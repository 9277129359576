import styles from './Features.module.scss';
import Slider from '#components/ui/Slider';
import FeatureCard from '#components/ui/FeatureCard';
import i18next from 'i18next';

type Props = {
  features: {
    title: string;
    description: string;
    type: string;
  }[];
};

const renderSlide = (slide: { title: string; description: string; type: string }) => {
  const lang = i18next.language;

  return (
    <div className={`${styles.featuresWrapper} ${styles.mobile}`}>
      <FeatureCard feature={slide} lang={lang} />
    </div>
  );
};

const FeaturesMobile = ({ features }: Props) => {
  return (
    <Slider
      className={styles.featuresSlider}
      slides={features}
      renderSlide={renderSlide}
      slidesPerViewMobile="auto"
      sliderType="features"
    />
  );
};

export default FeaturesMobile;
